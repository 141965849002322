import React, { useRef, useState, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { CForm, CFormLabel, CFormInput, CButton } from '@coreui/react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';
import { Alert } from 'react-bootstrap';
import NewsMedia from 'src/components/news/NewsMedia';
import Select from 'react-select'
import useLocalStorage from 'src/components/store/useLocalStorage';
import ViewComponentWithDelay from 'src/components/viewComponentWithDelay/ViewComponentWithDelay';


const urlApi = '/news';
const urlRouter = '/news';

export async function LoaderNewsUpdate({ params }) {
  if (Object.keys(params).length != 0) {
    let res = await AxiosInstance.get(`${urlApi}/${params.newsId}`).then((response) => {
      return response;
    });
    return res;
  }
  return false;
};

const newsForm = () => {

  const { data } = useLoaderData();
  //блок для завантаження ключа tiny
  // const [watchedItem,setWatchedItem] = useLocalStorage('tinyKey')
  const watchedItem = useLocalStorage('tinyKey')
  // const mainEditorItem = useLocalStorage('mainEditor')
  const [tinyKey, setTinyKey] = useState(watchedItem || '')
  // const [mainEditorKey, setMainEditorKey] = useState(mainEditorItem || '')

  /////////////////////////////////
  const [name, setName] = useState((data != undefined) ? data.name : '');
  const [newsShot, setNewsShot] = useState((data != undefined) ? data.newsShot : '');
  const editorRef = useRef(null);//реф для TinyMCE
  // const rteContentRef = useRef(null); // реф для React-rte
  const navigate = useNavigate();
  const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
  const [myData, setMyData] = useState((data != undefined) ? data : false);

  //стан для opacity
  const [opacity, setOpacity] = useState((data != undefined && data.opacityCard !== '' && data.opacityCard !== null && data.opacityCard !== undefined) ? data.opacityCard : null);
  const customStyles = {
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999,
    }),
  };

  useEffect(() => {
    if (watchedItem) {
      setTinyKey(watchedItem)
    }
    // if (mainEditorItem) {
    //   setMainEditorKey(mainEditorItem)
    // }
    // }, [watchedItem, mainEditorItem])
  }, [watchedItem])

  useEffect(() => {
    setMyData(data);
  }, [data]);

  // Викликає popup з власного компонента
  const popup = MyToastify();
  // Текст для popup
  const popupTextPutSuccess = "Оновлено";
  const popupTextPostSuccess = "Створено";
  const popupTextPutWarning = "Не оновлено";
  const popupTextPostWarning = "Не створено";
  // Ключ для Editor
  // const apiKey = process.env.REACT_APP_TINYMCE_API_KEY;

  //значеня для селекту opacity
  const opacityValues = [
    { value: '0', label: 'Прозора' },
    { value: '0.6', label: 'Напівпрозора' },
    { value: '1', label: 'Непрозора' },
  ];

  // Функція для кнопки "Зберегти"
  const handleSubmit = event => {
    event.preventDefault();
    let article = {}
    // if (editorRef.current && mainEditorKey === 'TinyMCE') {
    if (editorRef.current) {
      //умова номер один
      // console.log(editorRef.current.getContent());
      article = {
        name: name,
        newsShot: newsShot,
        newsFull: editorRef.current.getContent(),
        date: date,
        // active: ""
        opacityCard: opacity
        //  opacity !== '' ? opacity : '0'
      }
      //закриваємо першу умову перед else , а не в кінці handleSubmit як раніше
      //додаткова умова
    } //раніше ця дужка була в кінці handleSubmit
    // else if (rteContentRef.current && mainEditorKey === 'React-rte') {
    //   article = {
    //     name: name,
    //     newsShot: newsShot,
    //     newsFull: rteContentRef.current.getValue(),
    //     date: date,
    //     // active: ""
    //     opacityCard: opacity
    //     //  opacity !== '' ? opacity : '0'
    //   }
    // }

    // Для оновлення новини
    if (data != undefined) {
      // console.log('News put article', article);
      AxiosInstance.put(`${urlApi}/${data.id}`, article).then((response) => {
        // console.log("put news response", response);
        if (response.status === 200) {
          popup.toastifySuccess(popupTextPutSuccess);
          setMyData(response.data);
        } else {
          popup.toastifyWarning(popupTextPutWarning);
        }
      });
    } else {
      // Для створення новини
      AxiosInstance.post(urlApi, article).then((response) => {
        // console.log("response post news", response);
        if (response.status === 201) {
          // Popup про успішні зміни
          popup.toastifySuccess(popupTextPostSuccess);
          // alert("Дані оновлено");
          navigate(`${urlRouter}/update/${response.data.id}`);
        } else {
          popup.toastifyWarning(popupTextPostWarning);
        }
      });
    }
    //тут невеликі зміни
    // }
  };
  const handleChangeOpacity = (selectedOption) => {
    setOpacity(selectedOption.value);
    // console.log('Selected Opacity:', selectedOption.value);
  };

  return (
    <>
      {/* Popup про успішні зміни (справа зверху) */}
      <ToastContainer />

      {/* Назва категорії та зображення */}
      {
        (myData != false && myData != undefined) &&
        <>
          <h1 className="mb-3">{myData.name}</h1>
          <NewsMedia
            myData={myData} urlApi={urlApi}
            toastifySuccess={popup.toastifySuccess} toastifyWarning={popup.toastifyWarning}
          />
        </>
      }

      <CForm onSubmit={handleSubmit} className="mb-3">
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Назва</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput1" value={name} onChange={(e) => setName(e.target.value)} />
        </div>
        <div className='row'>
          <div className='col'>
            <div className="mb-3">
              <CFormLabel htmlFor="exampleFormControlInput1">Короткий опис</CFormLabel>
              <CFormInput type="text" id="exampleFormControlInput1" value={newsShot} onChange={(e) => setNewsShot(e.target.value)} />
            </div>
          </div>
          {/* додавання селекта */}
          <div className='col'>
            <div className="mb-3">
              <CFormLabel htmlFor="exampleFormControlInput1">Прозорість картки</CFormLabel>
              <Select
                placeholder='Виберіть прозорість'
                options={opacityValues}
                value={opacityValues.find((unit) => unit.value === opacity)}
                onChange={(value) => { handleChangeOpacity(value) }}
                styles={customStyles}
                menuPortalTarget={document.body}
              />
            </div>
          </div>
          {/* додавання селекта */}
        </div>
        <Alert variant='info'>Для коректного відображення на сайті текст має бути без зайвих стилів. Для очищення тексту копіюйте його з "Блокнот" або з "Notepad++", або набирати текст в редакторі нижче</Alert>
        <div className="mb-3">

          {/* {mainEditorKey === 'TinyMCE' && */}
          <Editor
            apiKey={tinyKey}
            onInit={(evt, editor) => editorRef.current = editor}
            initialValue={(data != undefined) ? data.newsFull : ''}
            init={{
              height: 500,
              // menubar: false,
              menubar: 'edit view insert format tools',
              // plugins: [
              //   'advlist autolink lists link image charmap print preview anchor',
              //   'searchreplace visualblocks code fullscreen',
              //   'insertdatetime media table paste code help wordcount'
              // ],
              plugins: [
                'advlist', 'autolink',
                'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                'fullscreen', 'insertdatetime', 'media', 'table', 'help', 'code'
              ],
              // toolbar: 'undo redo | formatselect | ' +
              //   'bold italic backcolor | alignleft aligncenter ' +
              //   'alignright alignjustify | bullist numlist outdent indent | ' +
              //   'removeformat | help',
              toolbar: 'undo redo | blocks fontname fontfamily fontsize | bold italic underline strikethrough superscript subscript | ' +
                'backcolor forecolor |' +
                'alignleft aligncenter alignright alignjustify | ' +
                'bullist numlist checklist outdent indent | removeformat | table help',
              font_size_formats: '10px 12px 14px 16px 18px 24px 36px 48px 72px',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
            }}
          />
          {/* } */}
        </div>
        <CButton type="submit" color="success">Зберегти</CButton>
      </CForm>
    </>
  );
};

export default newsForm;