import React, { useEffect, useState } from 'react';
import { CCard, CCardBody, CCardHeader, CCol, CRow, CButton, CFormSelect, CForm, CInputGroup, CFormLabel, CFormInput, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody, CFormCheck } from '@coreui/react';
import { DataGrid } from '@mui/x-data-grid';
import { Link, useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { cilPen, cilPlus, cilTrash } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { AiOutlineEye } from "react-icons/ai";
import Badge from 'react-bootstrap/Badge'
import Spinner from 'react-bootstrap/Spinner'
import FilterCategory from 'src/components/filter/FilterCategory';
import { RxOpenInNewWindow } from "react-icons/rx";
import Select from '../forms/select/Select';
import useSelectImageSize from 'src/components/store/useSelectImageSize';
import { confirmAlert } from 'react-confirm-alert';


const urlApi = '/products';
const urlRouter = '/products';
const cabinetLink = process.env.REACT_APP_SITE_URL;

const page = 1;
const itemsPerPage = 100;

export async function LoaderProducts({ params }) {
  let res = await AxiosInstance.get(urlApi, {
    params: {
      page: page,
      itemsPerPage: itemsPerPage,
      "g[0]": "product:read",
      "g[1]": "product:admin",
      "g[3]": "product:category",
      "g[4]": "product:attributeItems",
      "g[5]": "product:siteProduct",
    }
  }).then((response) => {
    return response;
  });
  return res;
};

const Products = () => {
  const { data } = useLoaderData();
  const [rows, setRows] = useState(false);
  const [rowCountState, setRowCountState] = React.useState(0);
  const [nameFilterSearch, setNameFilterSearch] = useState('');
  const [code1cFilterSearch, setCode1cFilterSearch] = useState('');
  const [barcodeFilterSearch, setBarcodeFilterSearch] = useState('');
  const [articleFilterSearch, setArticleFilterSearch] = useState('');
  const [isShowFilterSearch, setIsShowFilterSearch] = useState('');
  const [notCategory, setNotCategory] = useState(false);
  const [categoryFilter, setCategoryFilter] = useState(false);
  const [categoryClear, setCategoryClear] = useState(false);
  const [attributeFilter, setAttributeFilter] = useState('');
  // Заблокувати кнопку фільрації якщо не введені символи для пошуку
  const [disabled, setDisabled] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const imgSize = 90

  if (!rows) {
    setRowCountState(data['hydra:totalItems']);
    setRows(data['hydra:member']);
  };

  // Запит за продуктами (+фільтр)
  const getRowsTable = (p = 1, clear = false) => {
    setShowLoader(true);
    let params = {
      itemsPerPage: itemsPerPage,
      page: p,
      "g[0]": "product:read",
      "g[1]": "product:admin",
      "g[3]": "product:category",
      "g[4]": "product:attributeItems",
      "g[5]": "product:siteProduct",
    };

    if (clear == false) {
      if (nameFilterSearch != '') {
        params['name'] = nameFilterSearch;
      };
      if (code1cFilterSearch != '') {
        params['code1c'] = code1cFilterSearch;
      };
      if (barcodeFilterSearch != '') {
        params['barcode'] = barcodeFilterSearch;
      };
      if (articleFilterSearch != '') {
        params['article'] = articleFilterSearch;
      };
      if (isShowFilterSearch === true) {
        params['show'] = isShowFilterSearch;
      };
      if (notCategory === true) {
        params['categoryCnt'] = 0;
      };
      if (attributeFilter != '') {
        params['exists[attributeItems]'] = attributeFilter;
      };
      if (categoryFilter != false && categoryFilter.value != undefined) {
        params['category.id'] = categoryFilter.value;
      };
    }
    // console.log("request", request);
    AxiosInstance.get(urlApi, { params: params }).then((response) => {
      setRows(response.data['hydra:member']);
      setRowCountState(response.data['hydra:totalItems']);
      setShowLoader(false);
    });
  };

  const handlePageChange = (page) => {
    getRowsTable(page + 1);
  };

  // Заблокувати кнопку фільрації якщо не введені символи для пошуку
  // const disabledButton = (event) => {
  //   if (event.target.value) {
  //     setDisabled(false);
  //   } else {
  //     setDisabled(true);
  //   }
  // };

  // Якщо хоч одне поле пошуку заповнене, то розблокувати кнопку пошуку
  useEffect(() => {
    if (nameFilterSearch != "" || code1cFilterSearch != "" || barcodeFilterSearch != "" || articleFilterSearch != "" || categoryFilter != "" || isShowFilterSearch) {
      setDisabled(false);
    };
  }, [nameFilterSearch, code1cFilterSearch, barcodeFilterSearch, articleFilterSearch, isShowFilterSearch, categoryFilter]);

  // ----- Start handleFilterChange -----
  const handleNameFilterChange = (event) => {
    setNameFilterSearch(event.target.value);
    // disabledButton(event);
  };

  const handleCode1cFilterChange = (event) => {
    setCode1cFilterSearch(event.target.value);
    // disabledButton(event);
  };

  const handleBarcodeFilterChange = (event) => {
    setBarcodeFilterSearch(event.target.value);
    // disabledButton(event);
  };

  const handleArticleFilterChange = (event) => {
    setArticleFilterSearch(event.target.value);
    // disabledButton(event);
  };

  const handleShowFilterChange = (event) => {
    // const handleShowFilterChange = () => {
    setIsShowFilterSearch(!isShowFilterSearch);
    // disabledButton(event);
    // disabledButton();
  };
  const NoCategory = (event) => {
    // const handleShowFilterChange = () => {

    setNotCategory(!notCategory);
    // disabledButton(event);
    // disabledButton();
  };
  // ----- End handleFilterChange -----

  // Фільтрування по name, code1c, barcode, артиклу та "товар продається"
  const handleFilterSearch = (event) => {
    // console.log("handleFilterSearch event", event)
    event.preventDefault();
    getRowsTable();
  };

  const ChangeAttributeFilter = (event) => {
    console.log(event.target.value);
    setAttributeFilter(event.target.value);
    // disabledButton(false);
    // getRowsTable();
  }

  // Скинути фільтр
  const handleFilterReset = () => {
    setShowLoader(true);
    setCategoryFilter({});
    setNameFilterSearch("");
    setCode1cFilterSearch("");
    setBarcodeFilterSearch("");
    setArticleFilterSearch("");
    setIsShowFilterSearch("");
    setCategoryClear(true);
    setDisabled(true);
    setNotCategory(false);
    getRowsTable(1, true);

  };

  function Deleted(row) {
    confirmAlert({
      title: 'Ви впевнені що хочите видалити цей пункт ?',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Так',
          onClick: () => {
            const response = AxiosInstance.put(`${urlApi}/${row.id}`, {deleted:true}).then((response) => {
              getRowsTable();
            });
          }
        },
        {
          label: 'Ні',
          onClick: () => {
            return;
          }
        }
      ]
    });
  };
  const Cats = ({ data }) => (
    <>
      {data.map((c) => (
        <Badge key={c.id} variant="light" className='bg-light text-secondary'>{c.name}</Badge>
      ))}
    </>
  );
  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 80,
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Link
              to={'/products/read/' + params.row.id} title="Перейти на товар в новому вікні" target='_blank'
            >
              {params.row.id} <RxOpenInNewWindow />
            </Link>
            <a
              href={`${cabinetLink}/product/${params.row.id}`} title="Перейти на товар на сайті" target='_blank'
            >
              <small>Cайт</small>
            </a>
          </div>
        )
      }

    },
    {
      field: 'image',
      headerName: 'Image',
      width: 75,

      editable: true,
      renderCell: (params) => {
        let url = '';
        if (params.row.mainMedia != undefined && params.row.mainMedia != undefined) {
          url = process.env.REACT_APP_SERVER_URL + '' + params.row.mainMedia.contentUrl;
          if (params.row.mainMedia.resizeImagings != undefined && params.row.mainMedia.resizeImagings.length > 0) {
            url = useSelectImageSize(params.row.mainMedia, imgSize)
            // process.env.REACT_APP_SERVER_URL + '' + params.row.mainMedia.resizeImagings[0].contentUrl;
          }
        }

        // console.log('mainMedia', params.row.mainMedia)
        return (
          <img
            src={url}
            style={{ height: '45px', width: 'auto' }}
          />
        )
      }
    },
    {
      field: 'name', headerName: 'Назва', width: 300,
      editable: true,
      renderCell: (params) => {
        return (
          <div className='d-flex flex-column'>
            <Link
              to={'/products/read/' + params.row.id} title="Перейти до перегляду товару"
              style={{ textDecoration: "none", color: "rgba(0, 0, 0, 0.87)", width: '100%' }}
            >
              {params.row.name}
            </Link>
            {(params.row.siteProduct != null) && <Link className='bg-light text-secondary badge bg-primary' to={'/site-products/update/' + params.row.siteProduct.id} target='_blank'> {params.row.siteProduct.name} </Link>}
          </div>

        );
      }
    },
    {
      field: 'categoryCnt', headerName: 'Категорії', width: 300,
      editable: true,
      renderCell: (params) => {

        return (params.row.category != null && params.row.category != undefined && params.row.category.length > 0) &&
          <><Badge variant="light" className='bg-light text-secondary'>{params.row.categoryCnt})</Badge> <Cats data={params.row.category} /></>;
      }
    },
    {
      field: 'attributeItems', headerName: 'Атрибути', width: 300,
      editable: true,
      renderCell: (params) => {
        return (params.row.attributeItems != null && params.row.attributeItems != undefined && params.row.attributeItems.length > 0) &&
          <><Badge variant="light" className='bg-light text-secondary'>{params.row.attributeItems.length}</Badge> <Cats data={params.row.attributeItems} /></>;
      }
    },
    {
      field: 'show', headerName: 'Продається', width: 80,
      renderCell: (params) => {
        let displayShow = "Ні";
        if (params.row.show === true) {
          displayShow = "Так"
        }
        // console.log('displayShow', displayShow);
        return displayShow;
      }
    },
    {
      field: 'balance', headerName: 'Баланс', width: 70,
      editable: true,
      renderCell: (params) => {
        return <Link to={`/stage-balance/${params.row.id}`} target='_blanck'>{(params.row.balance != undefined && params.row.balance != null) ? params.row.balance : 0}</Link>;
      }
    },
    // { field: 'description', headerName: 'Опис', width: 150 },
    { field: 'article', headerName: 'article', width: 70 },
    { field: 'code1c', headerName: 'code1c', width: 110 },
    { field: 'price', headerName: 'Ціна', width: 100 },

    {
      field: "action_1",
      headerName: "Перегляд",
      sortable: false,
      width: 80,
      renderCell: (params) => {
        return (
          <Link to={`${urlRouter}/read/${params.row.id}`} title="Перейти до перегляду товару">
            <CButton color="dark" variant="outline">
              <AiOutlineEye size={20} />
            </CButton>
          </Link>
        );
      }
    },
    {
      field: "action_2",
      headerName: "Видалити",
      sortable: false,
      width: 80,
      renderCell: (params) => {
        return (
        
            <CButton color="danger" variant="outline" onClick={() => Deleted(params.row)}>
             <CIcon icon={cilTrash} customClassName="nav-icon" height={20} />
            </CButton>
          
        );
      }
    },
  ];

  // Натиснення кнопки: "Enter" - виконати пошук; "Escape" - скинути пошук 
  const keyDown = (event) => {
    // Виконати пошук
    if (event.key === 'Enter' && !disabled) {
      event.preventDefault();
      handleFilterSearch(event);
    };
    // Скинути пошук
    if (event.key === 'Escape') {
      event.preventDefault();
      handleFilterReset(event);
    };
  };

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CAccordion flush>
            <CAccordionItem>
              <CAccordionHeader><strong>Фільтр</strong></CAccordionHeader>
              <CAccordionBody>
                <CRow className="my-3 mx-2">
                  <CCol md={9} className="me-auto">
                    <CRow>
                      <CCol md={3} className='filter-field'>
                        <CFormInput
                          placeholder="Назва" id="nameFilterSearch" aria-label="Пошук" className="me-2"
                          onChange={handleNameFilterChange} value={nameFilterSearch} onKeyDown={keyDown} autoComplete="off"
                        />
                      </CCol>
                      <CCol md={3} className='filter-field'>
                        <CFormInput
                          placeholder="Артикул" id="articleFilterSearch" aria-label="Пошук" className="me-2"
                          onChange={handleArticleFilterChange} value={articleFilterSearch} onKeyDown={keyDown} autoComplete="off"
                        />
                      </CCol>
                      <CCol md={3} className='filter-field'>
                        <CFormInput
                          placeholder="code1c" id="code1cFilterSearch" aria-label="Пошук" className="me-2"
                          onChange={handleCode1cFilterChange} value={code1cFilterSearch} onKeyDown={keyDown} autoComplete="off"
                        />
                      </CCol>
                      {/* <CCol md={3} className='filter-field'>
                        <CFormInput
                          placeholder="Штрих-код" id="barcodeFilterSearch" aria-label="Пошук" className="me-2"
                          onChange={handleBarcodeFilterChange} value={barcodeFilterSearch} onKeyDown={keyDown} autoComplete="off"
                        />
                      </CCol> */}
                      <CCol md={3} className='filter-field'>
                        <FilterCategory setData={setCategoryFilter} clear={categoryClear} />
                      </CCol>
                      <CCol md={2} className='filter-field mt-md-2'>
                        <CFormCheck id="exampleFormControlInput8" label="Товар продається" checked={isShowFilterSearch} onChange={(event) => handleShowFilterChange(event)} />
                      </CCol>
                      <CCol md={2} className='filter-field mt-md-2'>
                        <CFormCheck id="exampleFormControlInput8" label="не має категорій" checked={notCategory} onChange={(event) => NoCategory(event)} />
                      </CCol>
                      <CCol md={2} className='filter-field mt-md-2'>
                        {/* <CFormCheck id="exampleFormControlInput8" label="не має категорій" checked={notCategory} onChange={(event) => NoCategory(event)} /> */}
                        <label>Атрибут</label>
                        <CFormSelect aria-label="Default select example" onChange={(event) => ChangeAttributeFilter(event)}>
                          <option value=''>Всі</option>
                          <option value="1">з атрибутами</option>
                          <option value="0">без атрибутів</option>
                        </CFormSelect>
                      </CCol>
                    </CRow>
                  </CCol>
                  <CCol md={3} style={{ textAlign: "end" }}>
                    <CButton className="me-2" type="submit" color="secondary" onClick={handleFilterSearch} >Пошук</CButton>
                    <CButton type="submit" color="secondary" onClick={handleFilterReset}>X</CButton>
                  </CCol>
                </CRow>
              </CAccordionBody>
            </CAccordionItem>
          </CAccordion>

          {/* Розширений функціонал є на сторінці "Список цін" */}
          {/* <CAccordion flush>
            <CAccordionItem>
              <CAccordionHeader><strong>Вивантеження товарного фіду</strong></CAccordionHeader>
              <CAccordionBody>
                <CRow className="my-3 mx-2">
                  <CCol md={3} style={{ textAlign: "start" }}>
                    <a href={`${process.env.REACT_APP_SERVER_URL}/products/export-xml`} target='_blank'>
                      <CButton className="me-2" color="secondary">Завантажити</CButton>
                    </a>
                  </CCol>
                </CRow>
              </CAccordionBody>
            </CAccordionItem>
          </CAccordion> */}

          <CCardHeader>
            <strong>Номенклатура</strong> <small>затягуються з 1с автоматично</small>
          </CCardHeader>
          <CCardBody>
            <p className="text-medium-emphasis small">
              <Link to="/products/create"><CButton color="secondary"><CIcon icon={cilPlus} customClassName="nav-icon" height={15} />Створити</CButton></Link>
            </p>
            <div style={{ height: 600, width: '100%' }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={itemsPerPage}
                rowsPerPageOptions={[itemsPerPage]}
                pagination
                rowCount={rowCountState}
                loading={showLoader}
                paginationMode="server"
                onPageChange={handlePageChange}
              />
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default Products;
