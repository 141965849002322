import React, { useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { CForm, CFormLabel, CFormInput, CButton, CCard, CCardBody, CCardHeader, CCol, CRow, } from '@coreui/react';
import { Link, useLoaderData, useNavigate } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';
import useLocalStorage from 'src/components/store/useLocalStorage';


const tokenStr = localStorage.getItem('user');
const header = { headers: { "Authorization": `Bearer ${tokenStr}` } };

const urlApi = '/pages';
const urlRouter = '/site-pages';

export async function LoaderPagesUpdate({ params }) {
  if (Object.keys(params).length != 0) {
    let res = await AxiosInstance.get(`${urlApi}/${params.newsId}`).then((response) => {
      return response;
    });
    return res;
  };
  return false;
};


const pagesForm = () => {
  const navigate = useNavigate();
  const { data } = useLoaderData();
  const [name, setName] = useState((data != undefined) ? data.name : '');
  const [synonym, setSynonym] = useState((data != undefined) ? data.synonym : '');
  const [mainImg, setmainImg] = useState((data != undefined) ? data.images : '');
  //блок для завантаження ключа tiny
  const watchedItem = useLocalStorage('tinyKey')
  const [tinyKey, setTinyKey] = useState(watchedItem || '')
  /////////////////////////////////
  const editorRef = useRef(null);
  // Ключ для Editor
  const apiKey = process.env.REACT_APP_TINYMCE_API_KEY;

  // Викликає popup з власного компонента
  const popup = MyToastify();
  // Текст для popup
  const popupTextPostSuccess = "Сторінку створено";
  const popupTextPutSuccess = "Сторінку змінено";

  useEffect(() => {
    if (watchedItem) {
      setTinyKey(watchedItem)
    }
  }, [watchedItem])


  const handleSubmit = event => {
    event.preventDefault();
    if (editorRef.current) {
      // console.log(editorRef.current.getContent());
      const article = {
        name: name,
        synonym: synonym,
        content: editorRef.current.getContent(),
        active: 'on',
        images: mainImg,
      };
      if (data != undefined) {
        AxiosInstance.put(`${urlApi}/${data.id}`, article).then((response) => {
          if (response.status === 200) {
            // Popup про успішні зміни
            popup.toastifySuccess(popupTextPutSuccess);
          }
        });
      } else {
        AxiosInstance.post(urlApi, article).then((response) => {
          // console.log('post pages response', response);
          if (response.status === 201) {
            popup.toastifySuccess(popupTextPostSuccess);
            return navigate(`${urlRouter}/update/${response.data.id}`);
          }
        });
      };
    };
  };

  return (
    <>
      {/* Popup про успішні зміни (справа зверху) */}
      <ToastContainer />

      <CForm onSubmit={handleSubmit}>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Назва</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput1" value={name} onChange={(e) => setName(e.target.value)} autoComplete="off" />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Синонім</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput2" value={synonym} onChange={(e) => setSynonym(e.target.value)} autoComplete="off" />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Головне зображення</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput3" value={mainImg} onChange={(e) => setmainImg(e.target.value)} autoComplete="off" />
          <Link to="/media" target='_blanck'>перейти до медіа</Link>
        </div>
        <div className="mb-3">
          <Editor
            // apiKey={apiKey}
            apiKey={tinyKey}
            onInit={(evt, editor) => editorRef.current = editor}
            initialValue={(data != undefined) ? data.content : ''}
            init={{
              height: 500,
              // menubar: false,
              menubar: 'edit view insert format tools',
              // plugins: [
              //   'advlist autolink lists link image charmap print preview anchor',
              //   'searchreplace visualblocks code fullscreen',
              //   'insertdatetime media table paste code help wordcount'
              // ],
              plugins: [
                'advlist', 'autolink',
                'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                'fullscreen', 'insertdatetime', 'media', 'table', 'help', 'code'
              ],
              // toolbar: 'undo redo | formatselect | ' +
              //   'bold italic backcolor | alignleft aligncenter ' +
              //   'alignright alignjustify | bullist numlist outdent indent | ' +
              //   'removeformat | help',
              toolbar: 'undo redo | blocks fontname fontfamily fontsize | bold italic underline strikethrough superscript subscript | ' +
                'backcolor forecolor |' +
                'alignleft aligncenter alignright alignjustify | ' +
                'bullist numlist checklist outdent indent | removeformat | table help',
              font_size_formats: '10px 12px 14px 16px 18px 24px 36px 48px 72px',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
            }}
          />
        </div>
        <CButton className="mb-3" type="submit" color="success">Зберегти</CButton>
      </CForm>
    </>
  );
};

export default pagesForm;
